/* Global CSS entry point */
@import '@angular/cdk/text-field-prebuilt.css';
@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


.cdk-global-overlay-wrapper {
  position: absolute;
  bottom: 0;
}

.mat-bottom-sheet-container {
  background-color: white !important;
  padding: 0 !important;
  border-radius: 24px 24px 0 0;
  position: relative;
  z-index: 1001 !important;
  overflow: visible !important;
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000 !important;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

:root {
  /* Font family */
  --cureskin-font-display: 'Helvetica', sans-serif;
  --cureskin-font-body: 'Noto Sans', sans-serif;

  /* Font sizes */
  --cureskin-font-size-900: 36px;
  --cureskin-leading-900: 46px;
  --cureskin-font-size-800: 30px;
  --cureskin-leading-800: 39px;
  --cureskin-font-size-700: 24px;
  --cureskin-leading-700: 32px;
  --cureskin-font-size-650: 22px;
  --cureskin-leading-650: 32px;
  --cureskin-font-size-600: 20px;
  --cureskin-leading-600: 26px;
  --cureskin-font-size-500: 18px;
  --cureskin-leading-500: 24px;
  --cureskin-font-size-400: 16px;
  --cureskin-leading-400: 20px;
  --cureskin-font-size-300: 14px;
  --cureskin-leading-300: 20px;
  --cureskin-font-size-200: 12px;
  --cureskin-leading-200: 18px;
  --cureskin-font-size-100: 10px;
  --cureskin-leading-100: 15px;
  --cureskin-font-size-50: 8px;
  --cureskin-leading-50: 18px;


  /* Colors */
  --cureskin-gray-900: #000000;
  --cureskin-gray-800: #222222;
  --cureskin-gray-700: #464646;
  --cureskin-gray-600: #575757;
  --cureskin-gray-500: #616161;
  --cureskin-gray-400: #9E9E9E;
  --cureskin-gray-300: #CECECE;
  --cureskin-gray-200: #E0E0E0;
  --cureskin-gray-100: #f3f3f3;
  --cureskin-gray-80: #F3F3F3;
  --cureskin-gray-75: #FEFEFE;
  --cureskin-gray-50: #ffffff;


  --cureskin-purple-500: #613D86;
  --cureskin-purple-400: #76539A;
  --cureskin-purple-300: #D3C6DE;
  --cureskin-purple-200: #D3C6DD;
  --cureskin-purple-100: #EDE8F1;

  --cureskin-blue-500: #2E5CA3;
  --cureskin-blue-400: #4973B4;
  --cureskin-blue-200: #B6CCE7;
  --cureskin-blue-100: #E2EBF5;

  --cureskin-teal-700: #1E564F;
  --cureskin-teal-600: #0E796C;
  --cureskin-teal-500: #2E9380;
  --cureskin-teal-400: #4EAE9C;
  --cureskin-teal-300: #CDE9E1;
  --cureskin-teal-200: #BDDDD4;
  --cureskin-teal-100: #E5F1EE;

  --cureskin-green-500: #358327;
  --cureskin-green-400: #5FA653;
  --cureskin-green-300: #CEDC85;
  --cureskin-green-200: #EBF1CE;
  --cureskin-green-100: #EBF1CE;

  --cureskin-yellow-500: #B57728;
  --cureskin-yellow-400: #D1903E;
  --cureskin-yellow-200: #ECE089;
  --cureskin-yellow-100: #F7F3D0;
  --cureskin-yellow-50: #ddd0bc;

  --cureskin-orange-600: #F2771F;
  --cureskin-orange-500: #BF501F;
  --cureskin-orange-400: #D66736;
  --cureskin-orange-200: #F1C49C;
  --cureskin-orange-100: #F9E7D7;
  --cureskin-orange-75: #FFF6EE;
  --cureskin-orange-50: #FEF6EB;

  --cureskin-gold-400: #F1C644;

  --cureskin-red-500: #BE3A00;
  --cureskin-red-400: #d93025;
}

html {
  letter-spacing: 0 !important;
  font-weight: 500 !important;
}

@keyframes scan-IC1 {
  0% {
    top: 10%;
  }

  50% {
    top: 80%;
  }

  100% {
    top: 10%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes click {
  0% {
    opacity: 0.5;
    transform: translate(45%, 20px) scale(1);
  }

  40% {
    opacity: 1;
    transform: translate(-50%, 0px) scale(1);
  }

  60% {
    transform: translate(-50%, 0px) scale(0.8);
  }

  80% {
    transform: translate(-50%, 0px) scale(1);
  }
}

@keyframes scan-v2 {
  0% {
    top: 0%;
  }

  100% {
    top: calc(100% - 100px);
  }
}

@keyframes run {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.cs-icon-orange {
  filter: invert(49%) sepia(37%) saturate(1296%) hue-rotate(336deg) brightness(91%) contrast(83%);
}

.cs-icon-blue {
  filter: invert(44%) sepia(68%) saturate(463%) hue-rotate(177deg) brightness(84%) contrast(86%);
}

.anim-group .anim-fadeIn {
  opacity: 0;
  animation: anim-fadeIn 0.5s 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
}

.anim-group .anim-fadeIn:nth-child(3) {
  animation: anim-fadeIn 0.5s 0.2s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
}

.anim-group .anim-fadeIn:nth-child(2) {
  animation: anim-fadeIn 0.5s 0.1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
}

.anim-group .anim-fadeIn:first-child {
  animation: anim-fadeIn 0.5s 0s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
}

@keyframes anim-scale {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.dot1 {
  animation: jump 1s 0s ease-in-out infinite;
}

.dot2 {
  animation: jump 1s 0.2s ease-in-out infinite;
}

.dot3 {
  animation: jump 1s 0.5s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}


@keyframes anim-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-click {
  0% {
    transform: scale(0.975);
  }

  25% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes gift-rotate {
  0% {
    transform: rotate(-10deg);
  }

  10% {
    transform: rotate(10deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  30% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}


@keyframes anim-popup {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes timer_toolbar {
  from {
    padding-bottom: 68px;
    background-color: var(--cureskin-purple-200);
  }

  to {
    padding-bottom: 12px;
    background-color: var(--cureskin-gray-75);
    overflow: hidden;
  }
}

@keyframes timer_clock {
  from {
    bottom: 12px;
  }

  to {
    left: calc(100% - 160px);
    bottom: 19px;
  }
}

@keyframes from_0 {
  from {
    stroke-dasharray: 0, 440px;
  }
}

.from_0 {
  animation: from_0 1 linear forwards;
}

@keyframes anim-click {
  0% {
    transform: scale(0.975);
  }

  25% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.05, 1.05, 1.05);
  }

  80% {
    transform: scale3d(0.95, 0.95, 0.95);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

/* swiper in introduction page */
user-home,
user-shop,
app-checkup-photo-instruction {
  --swiper-pagination-color: var(--cureskin-gray-500);
}

user-home .swiper-pagination {
  bottom: -24px !important;
}

user-home #main-swiper:not(:has(.swiper-slide:last-child:nth-child(1))){
  padding-bottom: 8px !important;
}
user-home #main-swiper:not(:has(.swiper-slide:last-child:nth-child(1))) .swiper-pagination{
  bottom: -16px !important;
}

user-home #order-banner-swiper:not(:has(.swiper-slide:last-child:nth-child(1))) .swiper-pagination{
  bottom: -6px !important;
}

user-followup,
user-shop,
user-home {
  --swiper-pagination-color: black;
}

user-followup .swiper-pagination {
  bottom: -30px !important;
}

.btn-float {
  float: left !important;
}

app-checkup-view-experiment .swiper-slide {
  height: auto !important;
}

@keyframes bounce-x {

  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes headshake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes scale-out-center {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 0.3;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fly-animation {
  0% {
    opacity: 1;
  }

  5% {
    scale: 1.1;
  }

  10% {
    scale: 1.3;
  }

  15% {
    scale: 1.1;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.2;
    top: 24px;
    right: 24px;
    height: 40px;
  }
}

.fly-animation {
  animation: fly-animation 0.8s cubic-bezier(0.11, 0, 0.5, 0);
;
}

@keyframes fly-product {
  0% {
    opacity: 1;
    transform-origin: top right;
  }

  100% {
    opacity: 0;
    top: 24px;
    right: 24px;
    transform-origin: top right;
    scale: 0.2;
  }
}

.animate-fly_product {
  animation: fly-product 1s ease-out forwards;
  animation-delay: 0.5s;
}

.grecaptcha-badge {
  visibility: hidden;
}

.chat-textarea-input {
  min-height: 54px;
}

@keyframes fade-in-right {
  from {
    opacity: 0.2;
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  from {
    opacity: 0.2;
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-out-left {
  from {
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0.2;
  }
}

@keyframes fade-out-right {
  from {
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0.2;
  }
}


@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-260%);
  }
}

@keyframes fade_marquee {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

testimonials-widget {
  --swiper-pagination-color: black;
}

testimonials-widget .swiper-slide:not(.swiper-slide-active){
  margin-top: 16px;
}

testimonials-widget .swiper-slide-prev #testimonial-content {
  display: none;
}

testimonials-widget .swiper-slide-next #testimonial-content {
  display: none;
}

testimonials-widget .swiper-button-next{
  top: 49% !important;
  right: -30px !important;
  color: var(--cureskin-gray-500) !important;
}

testimonials-widget .swiper-button-prev{
  top: 49% !important;
  left: -30px !important;
  color: var(--cureskin-gray-500) !important;
}

testimonials-widget .swiper-button-next::after, .swiper-button-prev::after{
  font-size: 16px !important;
  font-weight: 700;
}

testimonials-widget .swiper-slide-prev #testimonial-header p{
  display: none;
}

testimonials-widget .swiper-slide-next #testimonial-header p{
  display: none;
}

.fading-grid-pattern-1 {
  background-image: url(https://cdn.cureskin.com/app/img/fading_grid_pattern_1.png) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100%;
}
.hide-scrollbar {
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.backdrop-blur-sm {
  background: rgba(0, 0, 0, 0.76);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

@keyframes aiAnimation {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes background_gradient {
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.shine-button:after {
  content: '';
  position: absolute;
  width: 30px;
  height: 100px;
  background: rgba(211, 211, 211, 0.30);
  top: -25px;
  left: -200px;
  animation: shine 4s infinite linear;
  rotate: 40deg;
}

.shine-button:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 100px;
  background: rgba(211, 211, 211, 0.30);
  margin-left: 45px;
  top: -25px;
  left: -200px;
  animation: shine 4s infinite linear;
  rotate: 40deg;
}

@keyframes shine {
  0% {left: -200px}
  100% {left: 200%}
}

.glass-morphism-effect {
  background: rgba(255, 255, 255, 0.22);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}

.apply-container::before {
  content: '';
  background: linear-gradient(45deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  animation: aiAnimation 20s linear infinite;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.shine-button:after {
  content: '';
  position: absolute;
  width: 30px;
  height: 200px;
  background: rgba(41, 207, 187, 0.2);
  top: -50px;
  left: -200px;
  animation: shine 4s infinite linear;
  rotate: 40deg;
}

.shine-button:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 200px;
  background: rgba(41, 207, 187, 0.2);
  margin-left: 45px;
  top: -50px;
  left: -200px;
  animation: shine 4s infinite linear;
  rotate: 40deg;
}

@keyframes shine {
  0% {left: -200px}
  100% {left: 200%}
}

.cart-page-bg-gradient{
  background: linear-gradient(91deg, #E4F6F1 -17.82%, #F7FFFD 84.63%);
}

.cart-page-bill-savings-gradient{
  background: linear-gradient(0deg, #FFF 0%, #F4F4F4 100%);
}

.cart-page-products-you-may-need-gradient {
  background: linear-gradient(102deg, #FFF5E3 9.19%, #FFFFF4 89.96%);
}

.cart-page-tag-bg-gradient {
  background: linear-gradient(278deg, rgba(255, 255, 255, 0.65) 8.68%, rgba(141, 232, 207, 0.65) 217.67%);
}
.font-plus-minus {
  font-family: "PingFang HK";
}

.gradient-border::after {
  translate: -50% -50%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bottomsheet-blur-overlay-sm {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
}

.custom-important-tag {
  position: relative;
  border-radius: 50px;
  background: linear-gradient(90deg, #E5F1EE 0%, #FFF 100%);
  padding: 10px 20px;
  display: inline-block;
  border: transparent;
}

.custom-important-tag::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px; /* Border thickness */
  border-radius: inherit;
  background: linear-gradient(90deg, #4EAE9C, #E5F1EE);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

/* Updated pagination container styles */
.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  width: fit-content !important;
  margin: 8px auto auto;
  border-radius: 60px;
  background-color: var(--cureskin-gray-300);
  height: 4px;
}

.custom-pagination .swiper-pagination-bullet {
  width: 21px;
  opacity: 0;
  border-radius: 4px;
  margin: 0 !important;
  height: 4px;
}

.custom-pagination .swiper-pagination-bullet-active {
  width: 21px;
  opacity: 1;
  background-color: var(--cureskin-gray-400);
  border-radius: 60px;
  margin: 0 !important;
  height: 4px;
}

@keyframes pulseScale {
  0%, 100% { transform: scaleY(1); }
  50% { transform: scaleY(1.5); }
}

.tw-animate-pulse-scale {
  animation: pulseScale 0.5s ease-in-out;
}

.instruction-card-container-bottom{
  background: linear-gradient(269deg, #FFFCF8 -3.22%, #F9E7D7 118.95%);
}

app-regimen-products-detail .swiper-wrapper {
  @apply tw-mx-2;
}

@keyframes timer_progress_bg {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

@keyframes timer_progress_bar {
  0% {
    width: 0%;
    background-color: var(--cureskin-yellow-400);
  }
  80% {
    background-color: var(--cureskin-yellow-400);
  }
  90% {
    background-color: var(--cureskin-green-400);
  }
  100% {
    background-color: var(--cureskin-green-400);
    width: 100%;
  }
}

@keyframes ticker_flip {
  95% {
    bottom: -32px;
  }
  100% {
    bottom: 0;
  }
}


app-product-view .swiper-wrapper {
  align-items: center;
}

app-product-view .active-pagination-class {
  position: relative;
}

app-product-view .active-pagination-class::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 3px black;
}